<template>
  <Painel />
</template>

<script>
import Painel from '../components/Painel.vue';

export default {
  components: {
    Painel
  }
};
</script>

import Vuex from 'vuex';
import { moduleLogin } from './module/login';
import { modulePainel } from './module/painel';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login: moduleLogin,
    painel: modulePainel
  }
});

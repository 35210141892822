<template>
  <div class="container">
    <nav
      class="navbar is-spaced has-shadow"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item">
            <img :src="logo" width="130" alt=""/>
          </a>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-light" @click="desconect()">Sair</a>
          </div>
        </div>
      </div>
    </nav>
    <section class="hero is-fullwidth">
      <vue-topprogress ref="topProgress" :color="pbColor"/>
      <div class="hero-body">
        <div class="title">
          <div class="container" style="padding-left: 10px">
            <p>{{ user.nome }}</p>
          </div>
        </div>
        <div class="container">
          <nav
            class="pagination is-small"
            role="navigation"
            aria-label="pagination">
            <div @click.self.prevent="onFilterClick" class="pagination-previous pag-filter">
              <span class="icon is-small ic-filter">
                <img :src="icFilter" alt="Filtro"/>
              </span>
              <Filtro/>
            </div>
            <a
              class="pagination-previous"
              @click="buttonSearch(pagina.atual - 1, !disablePreviousBtn)"
              :disabled="disablePreviousBtn"
            >Anterior</a>
            <a
              class="pagination-next"
              @click="buttonSearch(pagina.atual + 1, !disableNextBtn)"
              :disabled="disableNextBtn"
            >Próxima</a>
            <ul class="pagination-list" style="list-style: none">
              <li v-for="(_, i) in pagina.quantidade" :key="i">
                <a
                  @click="buttonSearch(i, !disabledActualButton(i))"
                  class="pagination-link"
                  :class="{ 'is-current': disabledActualButton(i) }">
                  {{ i + 1 }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="container">
          <div class="content">
            <table class="table is-hoverable">
              <thead>
              <tr>
                <td class="ca has-text-weight-bold" style="width: 30px">
                  Duplicata
                </td>
                <td class="ca has-text-weight-bold">Parcela</td>
                <td class="la has-text-weight-bold" v-if="empresa === 'plasvale'">Situação</td>
                <td class="ca has-text-weight-bold">Vencimento</td>
                <td class="la has-text-weight-bold">Banco</td>
                <td class="ra has-text-weight-bold">Valor</td>
                <td class="ca has-text-weight-bold" style="width: 10px">
                  PDF
                </td>
                <td class="ca has-text-weight-bold" style="width: 10px">
                  Código
                </td>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(boleto, index) in boletos"
                :key="index"
                :class="{ vencido: isVencido(boleto) }"
              >
                <td class="ca">{{ boleto.documento }}</td>
                <td class="ca">{{ boleto.digito }}</td>
                <td class="la" v-if="empresa === 'plasvale'">{{ boleto.situacao }}</td>
                <td class="ca">{{ boleto.vencimento }}</td>
                <td class="la">{{ boleto.banco }}</td>
                <td class="ra">{{ formatValue(boleto.valor) }}</td>
                <td class="ca">
                  <img
                    alt=""
                    title="Visualizar boleto"
                    v-if="boleto.valido === 1"
                    @click="geraBoleto(boleto)"
                    :src="icPDF"
                    class="icone"
                  />
                  <img
                    alt=""
                    title="Solicitar boleto por e-mail"
                    v-if="boleto.valido === 0"
                    @click="openModalEmail(boleto)"
                    :src="icEmail"
                    class="icone"
                  />
                  <img
                    alt=""
                    title="Boleto indisponível, entre em contato com a empresa."
                    v-if="boleto.valido === 2"
                    :src="icDanger"
                    class="icone"
                  />
                </td>
                <td class="ca">
                  <img
                    alt=""
                    :src="icCopy"
                    :title="boleto.valido === 1 ? 'Copiar linha digitável' : ''"
                    class="icone"
                    :class="{ disable: boleto.valido !== 1 }"
                    @click="copyToClipboard(boleto)"
                  />
                </td>
              </tr>
              </tbody>
            </table>
            <input class="copy-me" type="text" ref="clipboard"/>
          </div>
        </div>
        <Modal
          m-wdith="80%"
          :status="modalBoleto"
          :on-cancel="() => (this.modalBoleto = false)">
          <embed
            :src="`${boletoPDF}#view=FitH&time=${Date.now()}`"
            class="embeded-custom"
            type="application/pdf"
          />
        </Modal>
        <Modal
          m-wdith="52%"
          :status="modalEmail"
          :on-cancel="() => (this.modalEmail = false)"
        >
          <header class="modal-card-head">Atenção</header>
          <section class="modal-card-body">
            <div class="container">
              <p>
                A duplicata selecionada está indisponível para impressão,
                gostaria de recebe-lá por e-mail?
              </p>
              <br/>
              <div class="columns">
                <div class="column is-6">
                  <div class="field">
                    <input
                      type="text"
                      class="input"
                      v-model="contato.nome"
                      placeholder="Contato (Opcional)"
                    />
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <the-mask
                      :mask="['(##) ####-####', '(##) #####-####']"
                      type="tel"
                      class="input"
                      v-model="contato.telefone"
                      :masked="true"
                      placeholder="Telefone"
                    />
                    <p class="help is-danger">{{ foneError }}</p>
                  </div>
                </div>
              </div>
              <p>
                <b>*</b> As duplicatas solicitadas serão enviadas para o e-mail
                cadastrado no sistema.
              </p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" @click="() => this.enviaEmail()">
              Solicitar
            </button>
            <button
              class="button"
              @click="
                () => {
                  this.modalEmail = false;
                  clearEmailContato();
                }
              "
            >
              Cancelar
            </button>
          </footer>
        </Modal>
      </div>
      <div class="container-popup">
        <div class="notification" :class="{ 'is-hidden': notifyVisibility() }">
          <button
            class="delete"
            v-on:click="() => (this.notifyMessage = null)"
          />
          <label ref="">{{ notifyMessage }}</label>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Modal from './shared/Modal'
import Filtro from './FiltroBoleto'
import {Boleto} from '@/classes/boleto'
import icDanger from '../assets/icons_danger.png'
import icEmail from '../assets/icon_email.png'
import icFilter from '../assets/icon_filter.png'
import icCopy from '../assets/icons-cod-barras.png'
import icPDF from '../assets/icon_pdf.png'
import {TheMask} from 'vue-the-mask'
import {empresa, logo, pbColor} from '@/urlRequest'
import {mapGetters, mapMutations} from 'vuex'

export default {
  components: {Modal, TheMask, Filtro},
  data: function () {
    return {
      icFilter,
      icPDF,
      icEmail,
      icCopy,
      icDanger,
      logo,
      pbColor,
      empresa,
      user: {},
      // boletos: Array[Boleto],
      modalBoleto: false,
      modalEmail: false,
      boletoEmail: Boleto,
      boletoPDF: String,
      notifyMessage: null,
      timeout: null,
      foneError: null,
      // pagina: {
      //   quantidade: 0,
      //   atual: 0
      // },
      contato: {
        nome: '',
        telefone: ''
      },
      rotas: [
        {nome: 'Boleto', rota: '/'},
        {nome: 'Sobre', rota: '/sobre'}
      ]
    }
  },
  computed: {
    ...mapGetters(['getBoletos', 'getPagina']),

    disablePreviousBtn: function () {
      return this.pagina.atual - 1 < 0
    },
    disableNextBtn: function () {
      return this.pagina.atual + 1 === this.pagina.quantidade
    },
    boletos: function () {
      return this.getBoletos
    },
    pagina: function () {
      return this.getPagina
    }
  },
  methods: {
    ...mapMutations(['setTopProgress']),

    openModalBoleto({urlFile}) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (window.innerWidth > 780) {
        this.modalBoleto = true
      } else {
        let el = document.createElement('a')
        el.href = urlFile
        el.download = 'Boleto.pdf'
        el.click()
      }

      this.timeout = setTimeout(() => {
        this.boletoPDF = urlFile
      }, 20)

      this.$refs.topProgress.done()
    },
    openModalEmail(item) {
      this.boletoEmail = item
      this.modalEmail = true
    },
    geraBoleto(boleto) {
      this.$refs.topProgress.start()
      this.$store.dispatch('buscaBoleto', {self: this, boleto})
    },
    enviaEmail() {
      const {nome, telefone} = this.contato
      this.foneError = null
      if (nome) {
        if (telefone.length === 0) {
          this.foneError = 'Preencha um telefone!'
          return
        } else if (telefone.length < 14) {
          this.foneError = 'Telefone incorreto!'
          return
        }
      } else {
        this.clearEmailContato()
      }

      this.$store.dispatch('enviaEmail', {
        self: this,
        boleto: this.boletoEmail,
        contato: this.contato
      })
      this.modalEmail = false
    },
    clearEmailContato() {
      this.contato.telefone = ''
      this.contato.nome = ''
    },
    buttonSearch(index, enabled) {
      if (enabled) {
        this.$store.commit('setAtualPagina', index)
        this.$store.dispatch('doSearch')
      }
    },
    disabledActualButton(index) {
      return this.pagina.atual === index
    },
    showNotification(message, complete) {
      this.clearEmailContato()
      if (complete) {
        this.$refs.topProgress.done()
      } else {
        this.$refs.topProgress.fail()
      }
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.notifyMessage = message
      this.timeout = setTimeout(() => {
        this.notifyMessage = null
      }, 4000)
    },
    formatValue(value) {
      let newValue = value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
      return newValue.replace('R$', '')
    },
    notifyVisibility() {
      return this.notifyMessage ? '' : 'is-hidden'
    },
    desconect() {
      this.$store.dispatch('logout')
    },
    isVencido(boleto) {
      const splited = boleto.vencimento.split('/')

      const now = new Date()
      const vencimento = new Date(splited[2], splited[1] - 1, splited[0])

      return now.getTime() > vencimento.getTime()
    },
    async copyToClipboard(boleto) {
      if (boleto.valido === 1) {
        this.$store.dispatch('copiarLinhaDigitavel', {
          boleto: boleto,
          next: (linhaDigitavel) => {
            const clipboardElement = this.$refs.clipboard;
            clipboardElement.value = linhaDigitavel;
            clipboardElement.select();

            document.execCommand('Copy')
            this.showNotification(
              'Linha digitável copiada para área de transferência.',
              true
            )
          }
        });


      }
    },
    onFilterClick() {
      this.$store.commit('changeFilterStatus')
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.setTopProgress(this.$refs.topProgress)

      this.$store.dispatch('doSearch')
      this.user = this.$store.getters.getUser
    })
  }
}
</script>
<style scoped>
.ic-filter {
  z-index: -1;
}

.pag-filter {
  position: static;
  cursor: pointer;
}

.table td {
  border: 0;
}

.embeded-custom {
  height: calc(100vh - 100px);
  width: 100%;
}

.la {
  text-align: left;
}

.ra {
  text-align: right;
}

.ca {
  text-align: center;
}

.container-popup {
  width: 425px;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 99999;
}

.notification {
  background-color: rgb(54, 54, 54);
  color: white;
}

.vencido {
  color: #c0141a;
}

.icone {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.disable {
  filter: opacity(50%);
  cursor: not-allowed;
}

.copy-me {
  opacity: 0;
  position: absolute;
  z-index: -9999;
  pointer-events: none;
}
</style>

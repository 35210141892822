import axios from 'axios'
import router from '../../router'
import {urlBoletoEmail, urlBoletoGerar, urlBoletoLinhaDigitavel, urlBoletoLista} from '@/urlRequest'
import {Boleto} from '@/classes/boleto'

export const modulePainel = {
  state: {
    boletos: Array[Boleto],
    pagina: {
      quantidade: 0,
      atual: 0,
      duplicata: '',
      banco: 0
    },
    filtro: {
      duplicatas: [],
      bancos: []
    },
    filterStatus: false,
    topProgress: null
  },
  mutations: {
    setBoletos: (state, boletos) => (state.boletos = boletos),
    setAtualPagina: (state, pagina) => (state.pagina.atual = pagina),
    setPagina: (state, pagina) => (state.pagina = pagina),
    setFiltro: (state, filtro) => (state.filtro = filtro),
    applyFilters: (state, {bancoID, duplicata}) => {
      state.pagina.banco = bancoID
      state.pagina.duplicata = duplicata
    },
    changeFilterStatus: (state) => (state.filterStatus = !state.filterStatus),
    setTopProgress: (state, topProgress) => (state.topProgress = topProgress)
  },
  actions: {
    doSearch({commit, state}) {
      state.topProgress.start()

      axios({
        url: urlBoletoLista,
        method: 'POST',
        withCredentials: true,
        data: state.pagina
      })
        .then(response => {
          commit('setBoletos', response.data.boletos)
          commit('setPagina', response.data.pagina)
          commit('setFiltro', response.data.filtro)
          // self.callback()
        })
        .catch(_ => {
          router.push('/console/login')
        })
        .finally(() => state.topProgress.done())
    },
    buscaBoleto({commit}, {self, boleto}) {
      axios({
        url: urlBoletoGerar,
        method: 'POST',
        withCredentials: true,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(boleto)
      })
        .then(response => {
          let array = response.data
          let file = new Blob([array], {type: 'application/pdf'})
          let ourl = URL.createObjectURL(file)
          self.openModalBoleto({urlFile: ourl})
        })
        .catch(error => {
          if (error.response.status === 404) {
            self.showNotification('Não foi possível gerar o boleto, entre em contato com a empresa.', false)
          } else {
            router.push('/console/login')
          }
        })
    },
    enviaEmail({commit}, {self, boleto, contato}) {
      axios({
        url: urlBoletoEmail,
        method: 'POST',
        withCredentials: true,
        data: {
          boleto: boleto,
          contato: contato
        }
      })
        .then(_ => {
          self.showNotification('Solicitação registrada com sucesso.', true)
        })
        .catch(err => {
          if (err.response.status === 401) {
            router.push('/console/login')
          } else {
            self.showNotification('Erro na solicitação por e-mail, tente novamente mais tarde.', false)
          }
        })
    },
    copiarLinhaDigitavel({commit}, {boleto, next}) {
      axios({
        url: urlBoletoLinhaDigitavel,
        method: 'POST',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        data: JSON.stringify(boleto)
      })
        .then(response => {
          next(response.data.linhaDigitavel);
        })
        .catch(error => {
          console.log(`Erro ao consultar linha digitável: ${error}`);
        })
    },
  },
  getters: {
    getBoletos: (state) => state.boletos,
    getPagina: (state) => state.pagina,
    filterStatus: (state) => state.filterStatus,
    getFiltro: (state) => state.filtro
  }
}

<template>
  <section class="hero is-fullheight is-fullwidth background-login">
    <div class="hero-body" style="padding: 0;">
      <div class="container" style="margin: 0">
        <div class="columns is-centered" style="width: 100vw; margin: 0">
          <div class="column box-login">
            <div class="box" style="padding: 25px;">
              <div class="content formulario">
                <form @submit.prevent="loginSubmit">
                  <div class="field">
                    <label class="label">CNPJ/CPF</label>
                    <the-mask
                      :mask="['###.###.###-##', '##.###.###/####-##']"
                      v-model="username"
                      type="text"
                      class="input"
                    />
                    <p class="help is-danger">{{ usernameError }}</p>
                  </div>
                  <div class="field">
                    <label class="label">Senha</label>
                    <input type="password" v-model="password" class="input" />
                    <p class="help is-danger">{{ passwordError }}</p>
                  </div>
                  <article
                    class="message is-danger"
                    :class="{ 'is-hidden': !loginError }"
                  >
                    <div class="message-header">
                      <p>Erro</p>
                    </div>
                    <div class="message-body">{{ loginError }}</div>
                  </article>
                  <div class="field">
                    <div class="columns is-mobile is-centered">
                      <div class="column is-half" style="width: 325px" v-if="enableCaptcha">
                        <vue-recaptcha
                          ref="recaptcha"
                          :sitekey="keyCaptcha"
                          :loadRecaptchaScript="true"
                          @verify="onCaptchaVerified"
                          @expired="onCaptchaExpired"
                        />
                        <p class="help is-danger">{{ captchaError }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <p class="control has-text-centered">
                      <button
                        class="button is-success"
                        :class="{ 'is-loading': loggingIn }"
                        type="submit"
                      >
                        Conectar
                      </button>
                    </p>
                  </div>
                  <div class="container">
                    <div class="columns is-mobile is-vcentered is-centered">
                      <div class="column is-2" v-if="urlFacebook">
                        <a :href="urlFacebook" target="_blank">
                          <i class="fa fa-facebook fa-lg" />
                        </a>
                      </div>
                      <div class="column is-2" v-if="urlInstagram">
                        <a :href="urlInstagram" target="_blank">
                          <i class="fa fa-instagram fa-lg" />
                        </a>
                      </div>
                      <div class="column is-2" v-if="urlTwitter">
                        <a :href="urlTwitter" target="_blank">
                          <i class="fa fa-twitter fa-lg" />
                        </a>
                      </div>
                      <div class="column is-2" v-if="urlPinterest">
                        <a :href="urlPinterest" target="_blank">
                          <i class="fa fa-pinterest-p fa-lg" />
                        </a>
                      </div>
                      <div class="column is-2" v-if="urlYoutube">
                        <a :href="urlYoutube" target="_blank">
                          <i class="fa fa-youtube-play fa-lg" />
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapState, mapActions, mapMutations } from 'vuex';
import { TheMask } from 'vue-the-mask';
import {
  urlFacebook,
  urlInstagram,
  urlTwitter,
  urlPinterest,
  urlYoutube,
  keyCaptcha,
  enableCaptcha
} from '../urlRequest';

export default {
  components: {
    TheMask,
    VueRecaptcha
  },
  data: function() {
    return {
      username: '',
      password: '',
      usernameError: null,
      passwordError: null,
      urlFacebook,
      urlInstagram,
      urlTwitter,
      urlPinterest,
      urlYoutube,
      keyCaptcha,
      enableCaptcha,
    };
  },
  computed: {
    ...mapState({
      loggingIn: state => state.login.loggingIn,
      loginError: state => state.login.loginError,
      captchaToken: state => state.login.captchaToken,
      captchaError: state => state.login.captchaError
    })
  },
  methods: {
    ...mapMutations(['errorCaptcha', 'updateCaptcha']),
    ...mapActions(['doLogin']),
    onCaptchaVerified: function(token) {
      this.updateCaptcha(token);
    },
    onCaptchaExpired: function() {
      this.updateCaptcha(null);
    },
    reloadCaptcha: function() {
      this.$refs.recaptcha.reset();
    },
    loginSubmit() {
      this.usernameError = null;
      this.passwordError = null;
      if (this.username && this.password && (!enableCaptcha || this.captchaToken)) {
        this.doLogin({
          username: this.username,
          password: this.password,
          onFail: this.reloadCaptcha
        });
      } else {
        if (!this.username) {
          this.usernameError = 'Preenchimento obrigatório.';
        }
        if (!this.password) {
          this.passwordError = 'Preenchimento obrigatório.';
        }
        if (enableCaptcha && !this.captchaToken) {
          this.errorCaptcha('Validação obrigatoria');
        }
      }
    }
  }
};
</script>

<style scoped>
.formulario {
  background-color: white;
}

.box {
  min-width: 360px;
}
</style>

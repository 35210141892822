import Vue from 'vue'; // Importando core do Vue.js
import App from './App.vue'; // Importando Componente Vue da aplicação
import router from './router'; // Importar o router da aplicação
import store from './store'; // Importar o store da aplicação
import { library } from '@fortawesome/fontawesome-svg-core'; // Importar o core do Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; // Importar plugin para Vue.js do Font-Awesome
import { faCloud } from '@fortawesome/free-solid-svg-icons'; // Importando os icones que serão utilizados
import vueTopprogress from 'vue-top-progress';
import '../css/theme.css';

Vue.use(vueTopprogress);
Vue.config.productionTip = true;

library.add(faCloud); // Registrando os icones utilizados

// Registrando o componente do font-awesome
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Inicializando a aplicação Vue.js
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

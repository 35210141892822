export class Boleto {
  constructor() {
    this.id = Number;
    this.documento = Number;
    this.digito = Number;
    this.valor = Number;
    this.banco = String;
    this.vencimento = String;
    this.valido = Number;
    this.linhaDigitavel = String;
    this.situacao = String
  }
}

// val id: Int,
//   val documento: Int,
//   val digito: Int,
//   val valor: BigDecimal,
//   val banco: String,
// @JsonFormat(shape = JsonFormat.Shape.STRING, pattern = "dd/MM/yyyy")
// val vencimento: LocalDate

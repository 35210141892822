import plasvale from './assets/logo-plasvale.png';
import circulo from './assets/logo-circulo.png';

const config = require('../config.json');

let image;
switch (config.empresa) {
  case 'plasvale':
    image = plasvale;
    break;
  case 'circulo':
    image = circulo;
}

export const logo = image;
export const pbColor = config.pbColor;
export const empresa = config.empresa;
export const keyCaptcha = config.keyCaptcha;
export const urlFacebook = config.urlFacebook;
export const urlInstagram = config.urlInstagram;
export const urlTwitter = config.urlTwitter;
export const urlPinterest = config.urlPinterest;
export const urlYoutube = config.urlYoutube;
export const enableCaptcha = config.useCaptha;

const urlRoot = config.urlRoot;

export const urlLogin = `${urlRoot}/loginUser`; // metodo de login que vai gerar um cookie
export const urlCheck = `${urlRoot}/check`; // metodo de validação de cookie
export const urlLogout = `${urlRoot}/logout`; // metodo para deslogar do serviço
export const urlBoletoLista = `${urlRoot}/boleto/lista`; // metodo que retorna uma pagina de boleto e a qtd de paginas
export const urlBoletoGerar = `${urlRoot}/boleto/gerar`; // metodo que gera um boleto e retorna um byteArray
export const urlBoletoEmail = `${urlRoot}/email/send`; // metodo que retorna a empresa selecionada
export const urlBoletoLinhaDigitavel = `${urlRoot}/boleto/linhaDigitavel`; // metodo de pegar linha digitavel de um boleto

import Vue from 'vue';
import Router from 'vue-router';
import Login from '../views/Login.vue';
import Painel from '../views/Painel.vue';
import NotFound from '../views/NotFound.vue';
import { guard } from './guard';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/console/login',
      name: 'login',
      component: Login
    },
    {
      path: '/console',
      name: 'painel',
      component: Painel,
      beforeEnter: guard
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    }
  ]
});

<template>
  <div v-if="status" class="modal is-active">
    <div class="modal-background" @click="onCancel"></div>
    <div class="modal-content" :style="{ width: `${mWdith}`}">
      <slot></slot>
      <button @click="onCancel" class="modal-close is-large"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      required: true,
      type: Boolean
    },
    onCancel: {
      required: true,
      type: Function
    },
    mWdith: {
      required: true,
      type: String
    }
  },
  computed: {
    isActivated() {
      return this.status ? 'is-active' : '';
    }
  }
};
</script>
<style scoped>
/*.modal-content {*/
  /*width: 740px;*/
  /*width: 80%;*/
/*}*/
</style>

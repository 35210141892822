<template>
  <div>
    <img :src="image" width="500px" height="500px" class="outerDiv" />
  </div>
</template>

<script>
import image from '../assets/page-not-found.png';
export default {
  data: function() {
    return {
      image
    };
  }
};
</script>

<style scoped>
</style>

import axios from 'axios';
import router from '../../router';
import {urlCheck, urlLogin, urlLogout} from '../../urlRequest';

export const moduleLogin = {
  state: {
    loggingIn: false,
    loginError: null,
    verifingUser: false,
    user: null,
    captchaToken: null,
    captchaError: null
  },
  mutations: {
    loginStart: state => (state.loggingIn = true),
    loginStop: (state, error) => {
      state.loggingIn = false;
      state.loginError = error;
    },
    cleanError: state => {
      state.loginError = null;
      state.captchaError = null;
    },
    updateUser: (state, user) => (state.user = user),
    verifyUserStart: state => (state.verifingUser = true),
    verifyUserStop: state => (state.verifingUser = false),
    logout: state => (state.user = null),
    updateCaptcha: (state, token) => (state.captchaToken = token),
    errorCaptcha: (state, error) => (state.captchaError = error)
  },
  actions: {
    doLogin({commit}, {username, password, onFail}) {
      commit('cleanError');
      commit('loginStart');
      axios({
        url: urlLogin,
        method: 'POST',
        withCredentials: true,
        auth: {
          username,
          password
        },
        data: {
          token: this.state.login.captchaToken
        }
      })
        .then(response => {
          const user = response.data;
          commit('loginStop');
          commit('updateUser', user);
          localStorage.setItem('user', JSON.stringify(user));
          router.push('/console');
        })
        .catch(error => {
          let message;
          if (error.response.status === 401) {
            message = 'Dados incorretos.';
          } else {
            message = error.response.data.message;
          }
          commit('loginStop', message);
          onFail();
        });
    },
    doVerifyUser({commit}, next) {
      commit('verifyUserStart');
      axios({
        url: urlCheck,
        method: 'GET',
        withCredentials: true
      })
        .then(_ => {
          let user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            commit('verifyUserStop');
            commit('updateUser', user);
            next('/console');
          } else {
            next('/console/login');
          }
        })
        .catch(_ => {
          commit('verifyUserStop');
          next('/console/login');
        });
    },
    logout({commit}) {
      axios({
        url: urlLogout,
        method: 'GET',
        withCredentials: true
      }).finally(() => {
        localStorage.clear();
        commit('logout');
        router.push('/console/login');
      });
    }
  },
  getters: {
    loggedIn: state => state.user != null,
    getUser: state => state.user
  }
};

<template>
  <div class='card base' :class='isActivated'>
    <div class='card-content'>
      <div class='field'>
        <label class='label'>Duplicata</label>
        <div class='control'>
          <div class='select'>
            <select v-model='duplicata'>
              <option :key='dup' v-for='dup in duplicatas'>
                {{ dup }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Banco</label>
        <div class='control'>
          <div class='select'>
            <select v-model='banco'>
              <option :key='ban.codigo' v-for='ban in bancos' :value='ban.codigo'>
                {{ ban.nome }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class='content'>
        <button class='button is-primary is-normal' @click.prevent='apply'>
          <span class='icon'>
            <i class='fa fa-check'></i>
          </span>
          <span>Aplicar</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: function() {
    return {
      banco: 0,
      duplicata: 'Todos'
    }
  },
  computed: {
    ...mapGetters({
      status: 'filterStatus',
      filtro: 'getFiltro',
      pagina: 'getPagina'
    }),

    isActivated: function() {
      return this.status ? '' : 'is-hidden'
    },
    bancos: function() {
      return [{ nome: 'Todos', codigo: 0 }, ...this.filtro.bancos]
    },
    duplicatas: function() {
      return ['Todos', ...this.filtro.duplicatas]
    }
  },
  watch: {
    isActivated: {
      deep: true,
      handler: function(el) {
        this.banco = this.pagina.banco
        this.duplicata = this.pagina.duplicata === ''
          ? 'Todos'
          : this.pagina.duplicata
      }
    }
  },
  methods: {
    ...mapMutations(['applyFilters']),
    ...mapActions(['doSearch']),

    apply: function() {
      this.applyFilters({
        bancoID: this.banco,
        duplicata: this.duplicata === 'Todos' ? '' : this.duplicata
      })
      this.$store.commit('changeFilterStatus')
      this.doSearch()
    }
  }
}
</script>
<style scoped>
.card-content {
  text-align: start;
}

.card-content .content {
  text-align: center;
}

.fa {
  color: #000;
}

.fa-check {
  color: white;
}

.base {
  position: absolute;
  top: 100%;
  z-index: 10;
  cursor: default;
}
</style>
